import $ from 'jquery';

/** import local dependencies */
import './webflow.js';
import 'slick-carousel';

require('lightgallery/dist/js/lightgallery');

/**
 * Anonymous function is autoexecutable.
 */
(function () {

  let initialHash = location.hash;
  const $html = document.querySelector('html');

  function openModal(hash){
    closeModal();
    let $el = document.querySelector(hash);
    if ( $el && $el.classList.contains('modal') ) {
      $html.classList.add('overflow');
      $el.classList.add('open');
    }else{
      const offsetTop = document.querySelector(hash).offsetTop;
      console.log('offset', offsetTop);

      scroll({
        top: offsetTop,
        behavior: "smooth"
      });
    }
  }

  function closeModal(){
    document.querySelectorAll('.modal').forEach($el => {
      $html.classList.remove('overflow');
      $el.classList.remove('open');
    });
  }

  function cleanHash(){
    history.pushState(null, null, '#');
  }

  window.addEventListener('hashchange', () => {
    let hash = window.location.hash;
    console.log(hash);
    if ( hash !== '' ) {
      openModal(hash);
    } else {
      closeModal();
    }
  });


  if (initialHash.length > 0) {
    openModal(initialHash);
  }

  var $anchorLinks = document.querySelectorAll('a[href^="#"]');
  $anchorLinks.forEach(function (link) {
    if (link.hash.length > 0) {

        link.addEventListener('click', function (ev) {
          ev.stopPropagation(); // ev.preventDefault()
          window.location.hash = link.hash
        });
    }

  });

  document.addEventListener('keydown', function(e) {
    if (e.which === 27) {
      closeModal();
      cleanHash();
    }
  });

  if($('.modal-close').length > 0){
    $('.modal-close').on('click', function(e){
      e.preventDefault;
      closeModal();
      cleanHash();
    });
  }

  window.onclick = function(event) {
    if(event.target.classList.contains('modal')){
      closeModal();
      cleanHash();
    }
  }

  /*
  * Desktop Gallery
  */
  $('.grid').each(function () {
    let galleryID = this.id;
    $(this).find('.grid-item').on('click', function(){
      $(this).lightGallery({
        galleryId: galleryID,
        download: false,
        index: $(this).index(),
        dynamic: true,
        dynamicEl: phpVariables.galleries['mobile'+galleryID].images
      });
    });
  });

  /*$('.flex-image-carousel').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true
  });*/

  


  /*$('.flex-image-carousel').on('init', function(event, slick){
    //init code goes here
    }).on('afterChange',function(e,o){
      
        //on change slide = do action
        $('.flex-image-carousel iframe').each(function(){
            $(this)[0].contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*'); 
            console.log('next slide',$(this)[0]);   
        });
  }).slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true
  });*/

  /*
     * The below example uses Slick Carousel, however this can be
     * extended into any type of carousel, provided it lets you
     * bind events when the slide changes. This will only work
     * if all framed videos have the JS API parameters enabled.
     */
    
    //bind our event here, it gets the current slide and pauses the video before each slide changes.
    $(".flex-image-carousel").on("beforeChange", function(event, slick) {
      var currentSlide, slideType, player, command;
      
      //find the current slide element and decide which player API we need to use.
      currentSlide = $(slick.$slider).find(".slick-current");
      
      //determine which type of slide this, via a class on the slide container. This reads the second class, you could change this to get a data attribute or something similar if you don't want to use classes.
      slideType = currentSlide.attr("class").split(" ")[1];
      console.log(slideType);
      
      //get the iframe inside this slide.
      player = currentSlide.find("iframe").get(0);
      console.log('player', player);
      
      if (slideType == "vimeo") {
        command = {
          "method": "pause",
          "value": "true"
        };
      } else {
        command = {
          "event": "command",
          "func": "pauseVideo"
        };
      }
      
      //check if the player exists.
      if (player != undefined) {
        //post our command to the iframe.
        player.contentWindow.postMessage(JSON.stringify(command), "*");
      }
    });
    
    //start the slider
    $(".flex-image-carousel").slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      adaptiveHeight: true
    });
    

})();
